import { useParams } from "react-router-dom";
import { useDashboardContext } from "../context-state/DashboardContext.jsx";
import { Button } from "@mui/material";
// get the current route
import { useNavigate } from "react-router-dom";
import { PATH_DASHBOARD } from "../routes/paths";


export const useOverrideMsg = () => {
    const { eventId, subId } = useParams()
    const { ticketContext, overrideMsgContext } = useDashboardContext()
    const { getTicketsOfEvent } = ticketContext
    const { setDashboardOverrideMsg } = overrideMsgContext
    const navigate = useNavigate()

    const lookForEventWarnings = (event) => {
        if (event) {
            const { isSeatsEvent } = event
            const ticketsOfEvent = getTicketsOfEvent(event._id)
            if (!ticketsOfEvent || !ticketsOfEvent?.length) {
                setDashboardOverrideMsg({
                    title: "שימו לב",
                    msg: "עדין לא הוגדרו כרטיסים לאירוע זה",
                    btn: (<Button color="warning" onClick={() => {
                        const destination = isSeatsEvent ? PATH_DASHBOARD.sub.event.view.seatsChart(subId, eventId) : PATH_DASHBOARD.sub.event.view.tickets(subId, eventId)
                        navigate(destination)
                        setDashboardOverrideMsg({})
                    }}>הגדרת כרטיסים</Button>)
                })
                return
            }
        }
        setDashboardOverrideMsg({})
    }

    return {
        lookForEventWarnings,
        setOverrideMsg: setDashboardOverrideMsg
    }
}
