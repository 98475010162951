// ----------------------------------------------------------------------

const path = (root, sublink) => {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  support: '/support',
  MEMBERSHIP_CLUB_MANAGEMENT: '/membership-club-management',

};

export const PATH_DASHBOARD_EVENT = {
  EDIT: '/edit',
  SETTINGS: '/settings',
  COUPONS: '/coupons',
  SEASON_TICKETS_DISCOUNT: '/season-tickets-discount',
  SELL_STATS: '/sell-stats',
  ORDERS: '/orders',
  LINKS: '/links',
  SEATS_CHART: '/seats-chart',
  TICKETS: '/tickets',
}

export const PATH_DASHBOARD_SUB = {
  USER: '/collaborate',
  EVENT: '/event',
  COUPONS: '/coupons',
  SALES_TABLE: '/sales-table',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: path(ROOTS_DASHBOARD, '/main'),
  profile: path(ROOTS_DASHBOARD, '/profile'),
  sellers: path(ROOTS_DASHBOARD, '/my-sellers'),
  sms_campaign: path(ROOTS_DASHBOARD, '/sms-campaign'),
  legal: path(ROOTS_DASHBOARD, '/legal'),
  seats_chart_admin: path(ROOTS_DASHBOARD, '/seats-chart-admin'),
  market_sale_stats: path(ROOTS_DASHBOARD, '/market-sale-stats'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  end_users: path(ROOTS_DASHBOARD, '/end-users'),
  season_ticket: path(ROOTS_DASHBOARD, '/season-ticket'),
  single_end_users: (endUserId) => path(ROOTS_DASHBOARD, `/end-users/${endUserId}`),
  sub: {
    root: path(ROOTS_DASHBOARD, '/sub'),
    view: (subId) => ({
      root: path(ROOTS_DASHBOARD, `/sub/${subId}`),
      collaborate: path(ROOTS_DASHBOARD, `/sub/${subId}/collaborate`),
      sales_table: path(ROOTS_DASHBOARD, `/sub/${subId}/sales-table`),
    }),
    event: {
      root: (subId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event`),
      new: (subId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/new`),
      view: {
        root: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}`),
        edit: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.EDIT}`),
        salesStats: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.SELL_STATS}`),
        coupons: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.COUPONS}`),
        seasonTicketsDiscount: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.SEASON_TICKETS_DISCOUNT}`),
        settings: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.SETTINGS}`),
        orders: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.ORDERS}`),
        links: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.LINKS}`),
        seatsChart: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.SEATS_CHART}`),
        tickets: (subId, eventId) => path(ROOTS_DASHBOARD, `/sub/${subId}/event/${eventId}${PATH_DASHBOARD_EVENT.TICKETS}`),
      }
    }
  }
};


export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
};
